export default [
  {name: 'techno', color: '#BD10E0'},
  {name: 'elektro', color: '#43a047'},
  {name: 'metal', color: '#7dd322'},
  {name: 'rap', color: '#ff8a65'},
  {name: 'ambient', color: '#ffd54f'},
  {name: 'punk', color: '#81c784'},
  {name: 'post-punk', color: '#ff5722'},
  {name: 'elektropunk', color: '#00b0ff'},
  {name: 'house', color: '#4dd0e1'},
  {name: 'rock', color: '#f06292'},
  {name: 'trance', color: '#81c784'},
  {name: 'indie', color: '#f44336'}
];
